.limited {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 1520px;
  margin: 0 auto;
  flex-direction: column;
  &_body, &_header {
    max-width: 1520px;
  }
  &_header {
    padding: 30px 18px;
    &-content {
      display: flex;
      justify-content: space-between;
      .lang {
        margin-left: auto;
        height: 40px;
        .btn-none {
          padding: 5px 3px;
        }
        &_list {
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &_body {
    width: 100%;
    flex: 1;
    position: relative;
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 600px;
      margin: 0 auto;
      margin-top: 10%;
      text-align: center;
      padding: 0 20px;
      h1 {
        margin-bottom: 20px;
      }
    }
    &-links {
      position: absolute;
      bottom: 20px;
      left: 5px;
    }
  }
}