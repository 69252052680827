.notification {
  padding: 18px 12px;
  background-color: var(--dark);
  &_body {
    display: flex;
    flex-direction: column;
    span {
      color: var(--white);
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
    &-message {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}