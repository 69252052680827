@import "login";
@import "lang";
@import "components";
@import "admin";
@import "dashboard";
@import "mobile";
@import "sidebar";
@import "notification";
@import "register";
@import "layout";
@import "filters";
@import "order";
@import "limited";
@import "loader";
@import "settings";
@import "catalog";
@import "inputs";
@import "terms";


:root {
  --main-color: #00A3A3;
  --main-color-light: #00CCCC;
  --light: #F5F5F5;
  --dark: #1B1B1B;
  --dark-87: #3D3D3D;
  --dark-58: #868686;
  --dark-80: #494949;
  --dark-82: #4B4B4B;
  --dark-67: #707070;
  --dark-104: #111111; 
  --dark-23: #DFDFDF; 
  --dark-25: #DADADA;
  --dark-15: #F4F4F4;
  --white: #fff;
  --rounded: 5px;
}

.underline {
  text-decoration: underline;
}

.border-bottom {
  border-bottom: 1px solid var(--dark-23);
}

.modal.fade.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
}

.text-23 {
  color: var(--dark-23)!important;
}

.text-67 {
  color: var(--dark-67)!important;
}

.text-87 {
  color: var(--dark-87)!important;
}

.text-58 {
  color: var(--dark-58)!important;
}

.text-100 {
  color: var(--dark-100)!important;
}

.text-104 {
  color: var(--dark-104)!important;
}

.contrast-sm-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}

.contrast-md-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.contrast-lg-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.xs-text {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
}

.sm-text {
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
}

.md-text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.lg-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.sm-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.md-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.lg-title {
  font-weight: 700;
  font-size: 30px;
  line-height: normal;
}


.modal {
  &-header {
    background-color: var(--white);
    padding: 15px 20px!important;
  }
  &-title {
    font-size: 30px;
    font-weight: 700;
  }
  &-dialog {
    max-width: 900px!important;
    pointer-events: all!important;
  }
  &-wrapper {
    height: fit-content;
    background-color: var(--dark-15);
    width: 100%!important;
    scroll-behavior: smooth;
  }
  &-body {
    padding: 0!important;
  }
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 1px solid var(--dark-25);
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }
}

.elipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-table {
  padding: 10px 30px;
}

input[type="radio"]:checked {
  border-color: var(--main-color);
  &::before {
    transform: scale(1);
  }
}

.items-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btn-none {
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 10px 18px;
  display: inline-flex;
  border-radius: var(--rounded);
}

.btn-none:disabled {
  background-color: var(--dark-80)!important;
}

.main-btn {
  background-color: var(--white);
  color: var(--main-color);
  transition: 0.2s;
  &:hover {
    color:var(--dark-87); 
  }
}

.second-btn {
  background-color: var(--white);
  color: var(--dark-58);
  transition: 0.2s;
  &:hover {
    color:var(--dark-87); 
  }
}

span, label {
  font-size: 14px;
}

a.link {
  line-height: 20px;
  font-weight: 400;
  color: var(--dark-82);
  text-decoration: underline;
  font-size: 14px;
}

.unset {
  all: unset;
}

a.unset {
  &:hover {
    cursor: pointer!important;
  }
}

.semibold {
  font-weight: 600;
}

.heavier {
  font-weight: 500;
}