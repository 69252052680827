.order, .orders, .order-details, .admin {
  &_header {
    padding: 19px 40px;
    &-draft-form {
      max-width: 400px;
    }
    &-buttons {
      margin-top: 10px;
    }
  }
  &_details, &_form, &_actions, &_body {
    padding: 19px 40px;
    max-width: 1520px;
  }
  &_details {
    .card, &-note {
      margin-top: 19px;
      max-width: 525px;
    }
  }
  &_actions {
    margin: 19px 0;
    &-wrapper {
      margin-left: 10px;
    }
  }
}

.orders-history, .orders-drafts {
  .order_body {
    max-width: 100%!important;
  }
}

.order, .admin {
  &_header {
    &-content {
      max-width: 1520px;
      padding-right: 60px;
    }
  }
  &-form {
    margin-right: 15px;
    &_body {
      &-left {
        padding-right: 5px!important;
      }
      &-meta {
        &_sku {
          word-break: break-all;
          padding-right: 2px;
        }
      }
    }
  }
}

.order-details {
  &_header {
    .row {
      margin-top: 8px;
    }
  }
  &_body {
    &-separator {
      padding-top: 30px;
    }
  }
}

.orders, .admin {
  &_header {
    .col-lg-4 {
      min-height: 100%;
    }
    &-content {
      height: 100%;
    }
    &-content.search-form {
      display: flex;
      justify-content: center;
      align-items: center;
      .form-group {
        position: relative;
        width: 100%;
        .form-control {
          text-indent: 28px;
        }
        svg {
          position: absolute;
          left: 14px;
          top: 16px;
        }
      }
    }
  }
}

.shipping-card, .summary-card {
  margin-top: 19px;
  max-width: 525px;
  &_rows {
    div {
      &:first-of-type {
        padding-left: 30px;
      }
    }
    &-header {
      padding: 17px 0;
    }
  }
  &_row {
    &-content {
      padding: 15px 0;
      &-extra {
        font-size: 12px;
        margin-left: 28px;
        position: absolute;
        bottom: 6px;
      }
      &:first-of-type {
        display: flex;
        align-items: center;
        padding-left: 30px!important;
      }
      &:last-of-type {
        padding-right: 30px;
      }
      .form-group {
        width: 100%;
        position: relative;
      }
      &-radio {
        margin-right: 10px;
        margin-top: 3px;
        cursor: pointer;
      }
      label {
        color: var(--dark-87);
      }
      svg {        
        position: absolute;
        right: 5px;
        top: 48px;
      }
    }
  }
  &_row.active {
    label {
      color: var(--dark);
      font-weight: 500;
    }
  }
}


.shipping-card {
  &_row {
    &-content {
      padding-bottom: 25px;
    }
  }
}

.summary-card {
  &_row {
    &-content {
      label, span {
        font-size: 16px;
        color: var(--dark-87);
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
}

.order-form, .order-table, .order-invoices, .order-overview {
  margin-top: 19px;
  &_header {
    background-color: var(--dark-15);
    border: 1px solid var(--dark-23);
    border-top-right-radius: var(--rounded);
    border-top-left-radius: var(--rounded);
    padding: 15px 0;
    width: 100%;
    .row {
      width: 100%;
    }
    &-left {
      padding-left: 30px!important;
    }
    label {
      font-size: 14px;
      font-weight: 600;
    }
  }
  &_footer {
    background-color: var(--white);
    width: 100%;
    padding: 15px 0;
    border: 1px solid var(--dark-23);
    border-bottom-right-radius: var(--rounded);
    border-bottom-left-radius: var(--rounded);
    border-top: none;
    &-wrapper {
      padding-left: 30px;
    }
  }
  &_body {
    color: var(--dark-87);
    border-bottom-left-radius: var(--rounded);
    border-bottom-right-radius: var(--rounded);
    &-loader {
      padding: 50px 0;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    background-color: var(--white);
    border-bottom: 1px solid var(--dark-23);
    border-left: 1px solid var(--dark-23);
    border-right: 1px solid var(--dark-23);
    &-row {
      .mobile-label {
        display: none;
      }
      position: relative;
      border-bottom: 1px solid var(--dark-23);
      &:last-of-type {
        border-bottom: none;
      }
    }
    &-img {
      overflow: hidden;
      img {
        height: 100px;
        object-fit: contain;
      }
    }
    &-left {
      padding-left: 30px!important;
    }
    &-meta {
      color: var(--dark-87);
      display: flex;
      height: 100%;
      align-items: center;
    }
    .amount-meta {
      .form-control {
        width: 50%;
        max-width: 80px;
        padding: 8px 0;
        text-indent: 5px;
      }
    }
    .stock-meta {
      &_info.available {
        color: #008900;
        font-weight: 500;
      }
      &_info.backorder {
        color: var(--dark-67);
        font-weight: 500;
      }
    }
    .delete-col {
      display: flex;
      justify-content: center;
      align-items: center;
      /*
      position: absolute;
      right: 0;
      width: fit-content;
      height: 100%;
      padding: 0;
      margin-right: 0px;
      */
    }
    &-col {
      padding: 20px 0;
      margin-right: 15px;
    }
  }
}

.order-table {
  background-color: var(--white);
  border-radius: var(--rounded);
  &_header {
    background-color: var(--white);
  }
  &_body {
    &-row {
      padding: 10px 0;
      &:nth-of-type(odd){
        background-color: var(--dark-15);
      }
      &-empty {
        padding-left: 30px!important;
      }
    }
    border-bottom-left-radius: var(--rounded);
    border-bottom-right-radius: var(--rounded);
  }
}

.order-invoices, .order-overview {
  &_body {
    &-row {
      padding: 10px 0;
    }
  }
}


@media screen and (max-width: 1320px) {
  .order {
    &_details {
      .shipping-card {
        .card_body {
          padding: 15px;
        }
        &_row {
          &-content {
            &:first-of-type {
              padding-left: 0!important;
            }
            &:last-of-type {
              padding-right: 0;
            }
          }
        }
      }
    }
    &_header {
      &-content {
        padding-right: 0;
      }
      &-draft {
        &-form {
          max-width: 100%;
        }
      }
    }
    &-form {
      &_header {
        display: none;
      }
      &_body {
        &-col {
          margin-right: 0!important;
        }
        .col-lg-2, .col-lg-3, .col-lg-1 {
          padding: 7px 12px;
        }
        &-left {
          padding: 5px 12px!important;
        }
        border-radius: var(--rounded);
        border-top: 1px solid var(--dark-23);
        &-meta, &-img {
          display: flex;
          justify-content: space-between;
          .mobile-label {
            font-weight: 600;
            display: block;
          }
        }
        &-img {
          .mobile-label {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .delete-col {
          padding: 3px 0;
          .order-form_body-col {
            padding: 0;
          }
        }
      }
    }
  }
  .order-table {
    &_header {
      display: none;
    }
    &_body {
      border-top: 1px solid var(--dark-23);
      border-radius: var(--rounded);
      padding: 10px 12px;
      &-row {
        border-bottom: none;
        margin-bottom: 12px;
        border-radius: var(--rounded);
        padding: 10px;
      }
      &-left {
        padding-left: 0!important;
      }
      &-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .order-overview {
    &_header {
      display: none!important;
    }
  }
  .order-invoices, .order-overview {
    &_header {
      display: none!important;
    }
    &_body {
      border-top: 1px solid var(--dark-23);
      border-radius: var(--rounded);
      &-left {
        padding-left: 0px!important;
      }
      &-row {
        padding: 10px 15px;
        .mobile-label {
          display: block;
          font-weight: 600;
        }
        .col {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
        flex-direction: column;
        &:first-of-type {
          border-top-left-radius: var(--rounded);
          border-top-right-radius: var(--rounded);
        }
      }
    }
  }
}