.catalog {
  &_content {
    &-count {
      padding: 10px 0;
      padding-left: 20px;
      span {
        margin-bottom: 0;
        color: var(--dark-58);
      }
    }
    &-rows {
      position: relative;
      max-width: 1520px;
      margin: 0 auto;
      &-col {
        max-width: 340px;
      }
    }
    &-drafts {
      position: absolute;
      width: 300px;
      max-height: 400px;
      overflow-y: scroll;
      background-color: var(--white);
      border: 1px solid var(--dark-23);
      border-radius: var(--rounded);
      right: 100px;
      top: 75%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      &-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: auto; */
        flex: 1;
        width: 100%;
      }
      &-row {
        width: 100%;
        padding: 8px 12px;
        height: fit-content;
        &-wrapper {
          padding: 0!important;
          display: flex;
          height: fit-content;
          justify-content: space-between!important;
          align-items: center!important;
          width: 100%;
        }
        .btn-none {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2px;
        }
        &:hover {
          background-color: var(--dark-15);
        }
      }
    }
    &-wrapper {
      position: relative;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      margin: 0 20px;
      align-items: center;
      .catalog-row {
        width: 100%;
        max-width: 1200px;
        &_image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 10px!important;
          padding-bottom: 10px!important;
          img {
            height: 100%;
            max-height: 100px;
            width: auto;
          }
        }
        &_actions {
          &-col {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  &_filters {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--dark-23);
    .filters {
      max-width: 1520px;
      &_body {
        .select {
          max-width: 100%;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .detail-modal {
    border-radius: var(--rounded);
    .modal-body {
      background-color: var(--white);
      max-height: 80vh;
      overflow-y: scroll;
      border-bottom-left-radius: var(--rounded);
      border-bottom-left-radius: var(--rounded);
    }
  }
  &_details {
    padding: 40px;
    &-meta {
      padding-left: 15px;
      &-content {
        margin-top: 40px;
        &-row {
          width: 100%;
          border-radius: var(--rounded);
          padding: 5px 10px;
          &:nth-of-type(odd){
            background-color: var(--dark-15);
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    &-description {
      margin-top: 35px!important;
      p {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
      &-title {
        border-bottom: 1px solid var(--dark-25);
      }
      &-content {
        margin-top: 10px;
        span {
        }
      }
    }
    &-images {
      &-selected {
        img {
          width: 100%;
        }
      }
      &-list {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        max-width: 100%;
        .btn-none {
          padding: 0!important;
        }
        &-item {
          width: 85px;
          margin-right: 8px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 85px;
            height: auto;
            object-fit: contain;
            border-radius: var(--rounded);
          }
          &:last-of-type {
            margin-right: 0;
          }
          &:hover {
            cursor: pointer;
          }
        }
        &-item.selected {
        }
      }
    }
  }
}


.catalog-card {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid var(--dark-23);
  border-radius: var(--rounded);
  overflow: hidden;
  background-color: var(--white);
  max-width: 340px;
  &_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .product-image {
      width: auto;
      height: 200px;
    }
  }
  &_body {
    .product-detail {
      padding: 5px 10px;
      justify-content: space-between;
    }
    .product-detail.name {
      padding: 0;
      margin: 20px 1px;
      font-size: 15px;
      p {
        margin-bottom: 0;
      }
    }
    .product-detail:not(.name){
      border-radius: var(--rounded);
      &:nth-of-type(even){
        background-color: var(--dark-15);
      }
    }
    margin-bottom: 20px;
  }
  &_footer {
    border-top: 1px solid var(--dark-23);
    padding-top: 20px;
    justify-content: space-between;
    .detail-btn, .order-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding: 0;
      svg {
        margin-right: 5px;
      }
    }
    .detail-btn {
      color: var(--dark-58);
    }
    .order-btn {
      color: var(--main-color)
    }
  }
}

@media screen and (max-width: 1320px) {
  .catalog {
    &_content {
      &-count {
        display: none;
      }
      &-rows {
        position: relative;
        max-width: 1520px;
        margin: 0 auto;
        &-col {
          max-width: 340px;
        }
      }
      &-drafts {
        position: absolute;
        width: 300px;
        max-height: 400px;
        overflow-y: scroll;
        background-color: var(--white);
        border: 1px solid var(--dark-23);
        border-radius: var(--rounded);
        right: 0;
        top: 75%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        &-loader {
          display: flex;
          justify-content: center;
          align-items: center;
          /* height: auto; */
          flex: 1;
          width: 100%;
        }
        &-row {
          width: 100%;
          padding: 8px 12px;
          height: fit-content;
          &-wrapper {
            padding: 0!important;
            display: flex;
            height: fit-content;
            justify-content: space-between!important;
            align-items: center!important;
            width: 100%;
          }
          .btn-none {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2px;
          }
          &:hover {
            background-color: var(--dark-15);
          }
        }
      }
      &-wrapper {
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        margin: 0 20px;
        align-items: center;
        .catalog-row {
          width: 100%;
          max-width: 1200px;
          &_image {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px!important;
            padding-bottom: 10px!important;
            img {
              height: 100%;
              max-height: 100px;
              width: auto;
            }
          }
          &_actions {
            margin-top: 15px;
            border-top: 1px solid var(--dark-23);
            padding: 8px 0!important;
            &-col {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            }
          }
        }
      }
    }
  }
}