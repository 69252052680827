.layout {
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  &_content {
    flex: 1 1 0;
    min-width: 0;
    background-color: var(--dark-15);
    &-header {
      background-color: var(--white);
      width: 100%;
      padding: 10px 30px;
      display: flex;
      border-bottom: 1px solid var(--dark-23);
      h1 {
        flex: 1;
        font-weight: 700;
        font-size: 30px;
      }
      .header-btn {
        background-color: var(--dark-104);
        color: var(--white);
        padding: 14px 30px;
        border-radius: var(--rounded);
        font-weight: 600;
        transition: 0.2s;
        &:hover{
          background:var(--main-color);
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .layout {
    flex-direction: column;
    &_content {
      &-header {
        .header-btn {
          display: none!important;
        }
      }
    }
  }
}