.dashboard {
  &_body {
    padding: 20px 30px;
    &-loader {
      display: flex;
      width: 100%;
      padding: 50px 0;
      justify-content: center;
      align-items: center;
    }
    &-content {
      &-empty, &-meta {
        max-width: 1520px;
        margin: 0 auto;
        background-color: var(--white);
        border-radius: var(--rounded);
        margin-top: 20px;
        padding: 20px;
      }
      &-meta {
        max-width: 100%;
        .section-title {
          margin-top: 0;
        }
      }
      .card {
        margin-top: 30px;
        .section-title {
          margin-top: 0;
        }
        &_header {
          border-top-left-radius: var(--rounded);
          border-top-right-radius: var(--rounded);
        }
        &_footer {
          border-bottom-left-radius: var(--rounded);
          border-bottom-right-radius: var(--rounded);
          &-link {
            font-size: 14px;
            font-weight: 600;
            color: var(--main-color);
            text-decoration: none;
          }
        }
        &_header, &_footer, &_body {
          background-color: var(--white);
          padding: 20px;
        }
        &_body {
          padding: 8px 20px;
          .table-header {
            margin: 15px 0;
          }
          .table-row, .table-header {
            .text-right {
              text-align: right;
            }
          }
          .table-row {
            padding: 8px 5px;
            border-radius: var(--rounded);
            label {
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: auto;
            }
            &:nth-of-type(even){
              background-color: var(--dark-15);
            }
            .table-col:not(.text-right){
              padding-right: 8px;
            }
          }
        }
      }
    }
  }
}