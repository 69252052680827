.filters {
  padding: 0 20px;
  box-sizing: border-box;
  &_body {
    margin-top: 14px;
    .select {
      &_input {
        color: var(--dark-87); 
        --chevron:url("data:image/svg+xml,<svg fill='white' stroke='rgb(132, 137, 132)' xmlns='http://www.w3.org/2000/svg' width='37px' height='37px' viewBox='0 0 25 20'><path stroke='rgb(132, 137, 132)' stroke-width='1.66' d='M5 7.5L10 12.5L15 7.5'/></svg>");
        background: var(--chevron) right center no-repeat white;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .form-group {
      position: relative;
      .form-control {
        text-indent: 28px;
      }
      svg {
        position: absolute;
        left: 14px;
        top: 16px;
      }
    }
  }
}