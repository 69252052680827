.lang {
  display: none!important;
  padding: 5px 20px;
  width: fit-content;
  display: inline-block;
  background-color: var(--light);
  border-radius: var(--rounded);
  &_list {
    display: flex;
    justify-content: center;
    align-items: center;
    &-option {
      margin: 0 5px;
      span {
        text-transform: uppercase;
        font-weight: 400;
      }
    }
    &-option.active {
      span {
        color: var(--main-color);
        font-weight: 600;
      }
    }
  }
}