.settings {
  .modal-dialog {
    max-width: 1000px!important;
  }
  &-modal {
    border-radius: var(--rounded);
    .modal-body {
      padding: 30px 20px!important;
      &_footer {
        position: relative;
        margin-top: 20px;
        .btn-dark {
          max-width: 200px;
        }
        .input-error {
          top: 50px;
          left: 215px;
        }
      }
    }
    max-width: 1200px!important;
    &_fields {
      .form-group {
        margin-right: 18px;
      }
    }
  }
  &_details {
    padding: 30px 0 0 30px;
    .row {
      margin-bottom: 8px;
      p {
        margin-bottom: 0;
      }
    }
    &-notice {
      padding-top: 20px;
    }
  }
  &_body {
    margin: 40px 30px 0 30px;
    background-color: var(--white);
    border-radius: var(--rounded);
    .section-title.light {
      padding: 20px;
      .section-title_icon {
        background-color: var(--light);
      }
    }
    &-table {
      border-radius: var(--rounded);
      &-header {
        padding: 15px 30px;
      }
      &-content {
        &-item {
          margin: 0 20px;
          padding: 10px 10px;
          border-radius: var(--rounded);
          &:nth-of-type(odd) {
            background-color: var(--dark-15);
          }
          &-actions {
            .btn-none {
              &:first-of-type {
                margin-right: 8px;
              }
            }
          }
        }
      }
      &-footer {
        margin-top: 15px;
        border-top: 1px solid var(--dark-23);
        padding: 20px;
        .add-user-btn {
          padding-left: 0!important;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}