.form-group {
  label {
    margin-bottom: 6px;
  }
  .form-control {
    padding: 14px 10px;
    &:focus {
      box-shadow: none;
      border-color: var(--main-color);
    }
  }
}

input.form-control {
  &:placeholder-shown {
    text-overflow: ellipsis!important;
    overflow: hidden;
  }
}

.btn-dark {
  width: 100%;
  border-radius: var(--rounded);
  padding: 14px 0;
  background-color: var(--dark);
  color: var(--white);
  font-weight: 600;
  border: none;
}

.btn-light {
  width: 100%;
  border-radius: var(--rounded);
  padding: 14px 0;
  background-color: var(--white);
  color: var(--main-color);
  font-weight: 600;
  border: none;
}

.btn-slim {
  padding: 10px 0;
}

/*
  Checkbox CSS
*/
/* Customize the label (the checkbox-container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  left: 1px;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #D7D9D7;
  border-radius: var(--rounded);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--main-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 7px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container {
  &_text {
    span, a {
      font-size: 14px;
    }
  }
}