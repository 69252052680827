.register {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &_header {
    width: 100%;
    background-color: var(--dark-104);
    padding: 10px 40px;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lang {
        margin-left: auto;
        height: 40px;
        padding: 5px 30px;
        background-color: var(--dark-87);
        .btn-none {
          padding: 5px 3px;
        }
        &_list {
          svg {
            margin-right: 5px;
          }
          &-option {
            color:var(--main-color-light);
          }
        }
      }
    }
  }
  &_top {
    background-color: var(--dark-80);
    &-content {
      padding: 50px 20px;
      max-width: 1198px;
      margin: 0 auto;
      h1 {
        margin-top: 10px;
      }
      p {
        margin-bottom: 10px;
      }
    }
    background-color: var(--dark);
  }
  &_body {
    flex: 1;
    margin: 0 auto;
    max-width: 1520px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    &-success {
      max-width: 1100px;
      flex: 1;
      min-height: 100%;
      height: 100%;
      &-content {
        p {
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    &-error {
      position: absolute;
      top: -48px;
      padding: 10px 8px;
      border-radius: var(--rounded);
      background-color: var(--err-54);
      span {
        color: var(--err-100);
      }
    }
    &-add {
      border-radius: var(--rounded);
      background-color: var(--white);
      margin-top: 40px;
      margin-bottom: 100px;
      &-header {
        padding: 20px;
        border-bottom: 1px solid var(--dark-23);
      }
      &-footer {
        padding: 30px 0px 30px 0px;
        .btn-dark {
          max-width: 250px;
        }
        .checkbox-container {
          display: flex !important;
          a {
            padding-left: 3px;
          }
        }
      }
      &-form {
        padding: 20px;
        .section-title {
          margin: 20px 0;
        }
        &-separator {
          padding-top: 20px;
        }
        .col-lg-4 {
          margin-top: 15px;
        }
        &-top, &-mid, &-bottom {
          padding-top: 0;
          padding-bottom: 30px;
          border-bottom: 1px solid var(--dark-23);
        }
        &-mid, &-bottom {
          padding-top: 15px;
        }
        &-mid.mid-last {
          padding-bottom: 40px;
        }
        &-mid.manager {
          border-bottom: none;
          padding-bottom: 0;
        }
        &-mid, &-bottom {
          .phone-input {
            display: flex;
            &_select {
              border: 1px solid var(--dark-23);
              border-right: none;
              padding-left: 10px;
              &:focus, &:active, &:target {
                border: 1px solid var(--dark-23);
                outline: none;
                border-right: none;
              }
            }
            &_number {
              border-left: none;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              &:focus {
                border: 1px solid var(--dark-23)!important;
                border-left: none!important;
              }
            }
          }
        }
        &-bottom {
          border-bottom: 0;
        }
        .form-group {
          position: relative;
          margin-right: 15px;
          svg {
            position: absolute;
            right: 10px;
            top: 48px;
          }
        }
      }
    }
  }
}

  