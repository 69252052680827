.login {
  background-color: #000;
  background-image: url('../../public/media/login-visual.svg');
  background-repeat: no-repeat;
  &-section {
    height: 100%;
    background-color: var(--white);
    position: relative;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  &-section {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  &_card {
    height: 100vh;
    width: 100%;
    max-height: 900px;
    margin: 0 30px;
    margin-left: 0;
    margin-top: 8px;
    padding: 0 5%;
    background-color: #fff;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lang {
        &_list {
          .btn-none {
            padding: 8px 6px;
          }
        }
      }
    }
    &-body {
      padding: 125px 0;
      width: 75%;
      max-width: 450px;
      margin: 0 auto;
      .text-group {
        text-align: center;
      }
      .form-group {
        margin-top: 40px;
      }
      &-error {
        position: absolute;
        top: 15px;
        padding: 10px 8px;
        border-radius: var(--rounded);
        background-color: var(--err-54);
        span {
          color: var(--err-100);
        }
      }
    }
    &-banner {
      background-color: var(--main-color);
      border-radius: var(--rounded);
    }
    &-link {
      position: absolute;
      left: 22px;
      bottom: 20px;
    }
  }
}

.ref {
  position: absolute;
  color: var(--dark-58);
  font-size: 12px;
  right: 18px;
  bottom: 15px;
}

@media screen and (max-width: 1320px) {
  .login {
    &-section {
      overflow-x: hidden;
      position: relative;
    }
    &_card {
      margin: 0;
      padding: 12px 10px;
      min-height: 100vh;
      &-header {
        .lang {
          padding: 5px 8px;
          padding-left: 12px;
          &_list {
            .btn-none {
              padding: 8px 12px;
            }
          }
        }
      }
      &-banner {
        margin-bottom: 30px;
      }
      &-footer {
        margin-top: -25px;
        padding-bottom: 25px;
      }
      &-link {
        padding-bottom: 15px;
        position: relative;
        margin-top: 8px;
        margin-left: 8px;
        bottom: 0;
        left: 0;
      }
    }
  }
}