.admin {
  &_header {
    margin-top: 20px;
    padding: 18px 30px!important;
    &-lookup {
      max-width: 1200px;
      display: flex;
      align-items: flex-end;
      .form-group {
        width: 75%;
        max-width: 400px;
      }
      .btn-dark {
        max-width: 150px;
        height: fit-content;
        margin-left: 20px;
        margin-bottom: 1px;
      }
    }
  }
  &_edit-user {
    .form-group {
      .form-control {
        padding: 8px 5px;
      }
    }
    padding-bottom: 5px;
    .err {
      padding: 10px 8px;
      width: fit-content;
      border-radius: var(--rounded);
      background-color: var(--err-54);
      span {
        color: var(--err-100);
      }
    }
  }
  &_body {
    max-width: 1520px;
    padding: 0 30px!important;
    &-error {
      position: absolute;
      top: -48px;
      padding: 10px 8px;
      border-radius: var(--rounded);
      background-color: var(--err-54);
      span {
        color: var(--err-100);
      }
    }
    &-add {
      border-radius: var(--rounded);
      background-color: var(--white);
      margin-top: 40px;
      margin-bottom: 100px;
      &-header {
        padding: 20px;
        border-bottom: 1px solid var(--dark-23);
      }
      &-footer {
        padding: 0 20px 30px 20px;
        .btn-dark {
          max-width: 250px;
        }
      }
      &-form {
        padding: 20px;
        .section-title {
          margin: 20px 0;
        }
        .col-lg-4 {
          margin-top: 15px;
        }
        &-top, &-mid, &-bottom {
          padding-top: 0;
          padding-bottom: 30px;
          border-bottom: 1px solid var(--dark-23);
        }
        &-mid, &-bottom {
          padding-top: 15px;
        }
        &-mid.manager {
          border-bottom: none;
          padding-bottom: 0;
        }
        &-mid, &-bottom {
          .phone-input {
            display: flex;
            &_select {
              border: 1px solid var(--dark-23);
              border-right: none;
              padding-left: 10px;
              &:focus, &:active, &:target {
                border: 1px solid var(--dark-23);
                outline: none;
                border-right: none;
              }
            }
            &_number {
              border-left: none;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              &:focus {
                border: 1px solid var(--dark-23)!important;
                border-left: none!important;
              }
            }
          }
        }
        &-bottom {
          border-bottom: 0;
        }
        .form-group {
          position: relative;
          margin-right: 15px;
          svg {
            position: absolute;
            right: 10px;
            top: 48px;
          }
        }
      }
    }
  }
  .company-mdl-dialog {
    max-width: 1200px!important;
  }
  .company-modal {
    border-radius: var(--rounded);
    .modal-body {
      padding: 8px 30px!important;
      .form-group {
        margin-right: 20px;
      }
      .add-user-btn {
        padding: 0;
        svg {
          margin-left: 8px;
        }
      }
      .err {

      }
    }
    &_footer {
      border-top: 1px solid var(--dark-23);
      margin-left: -30px;
      margin-right: -30px;
      padding: 12px 30px;
      margin-top: 20px;
      .btn-dark {
        max-width: 200px;
      }
    }
    &_users {
      margin-top: 30px; 
      margin-bottom: 30px;
    }
    &_status {
      margin-top: 20px;
    }
    &_details {
      margin-top: 8px;
      .row {
        margin-bottom: 8px; 
      }
    }
  }
  &_notification {
    margin: 0 auto;
    &-header {
      width: 100%;
      background-color: var(--white);
      padding: 20px;
      margin-top: 20px;
      border-top-right-radius: var(--rounded);
      border-top-left-radius: var(--rounded);
      border-bottom: 1px solid var(--dark-23);
      .section-title {
        padding: 0!important;
        margin: 0;
      }
    }
    &-body {
      width: 100%;
      background-color: var(--white);
      border-bottom-right-radius: var(--rounded);
      border-bottom-left-radius: var(--rounded);
      &-form {
        padding-bottom: 0;
        &-fields {
          padding: 20px 25px;
        }
        &-footer {
          padding: 12px 25px;
          border-top: 1px solid var(--dark-23);
          .btn-dark {
            margin-bottom: 0;
            width: 200px;
          }
          .delete-btn {
            font-size: 16px!important;
            font-weight: 500;
            margin-left: 15px;
            svg {
              margin-left: 8px;
            }
          }
        }
      }
      &-add {
        width: 100%;
        .btn-none {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px 20px;
          font-weight: 500;
          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }
}