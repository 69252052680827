@import './scss/style.scss';

html, body {
  padding: 0;
  height: 100%;
  color: #1b1c1b;
  scroll-behavior: smooth;
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: "Lato","Inter",sans-serif!important;
  text-rendering: geometricPrecision;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.47;
  background-color: #F1F2F1;
  scroll-behavior: smooth;
}


#root {
  min-height: 100%;
  overflow-y: visible;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  font-weight: 700;
  line-height: normal;
}

.page {
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100%;
  .row {
    width: 100%;
  }
}