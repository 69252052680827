.sidebar {
  width: 320px;
  background-color: var(--dark);
  min-height: 100%;
  position: relative;
  &_content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    width: 320px;
  }
  &_header {
    background-color: var(--dark-104);
    padding: 10px 30px;
    a {
      display: block;
      width: 100%;
    }
    &-home {
      float: right;
      margin-top: 5px;
    }
  }
  label {
    color: var(--dark-58);
    font-weight: 500;
    font-size: 12px;
  }
  p {
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
  &_body {
    &-top {
      box-sizing: border-box;
      padding: 15px 30px;
      &-section {
        padding: 10px 0;
        &:first-of-type {
          padding-top: 5px;
        }
        &-company {
          color: var(--white)!important;
          background-color: var(--dark-87)!important;
          border: none!important;
        }
        .form-group {
          position: relative;
          select {
            padding: 8px;
          }
          svg {
            position: absolute;
            right: 5px;
            top: 11px;
          }
        }
      }
    }
    &-section {
      border-top: 1px solid var(--dark-87);
      &-label {
        padding: 15px 30px;
        padding-bottom: 0px;
      }
      &-list {
        &-item {
          padding: 15px 30px;
          display: flex;
          p {
            font-weight: 600;  
          }
          .icon-wrapper {
            padding: 5px;
            background-color: var(--dark-87);
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--rounded);
            margin-right: 13px;
          }
        }
        &-item.active {
          background-color: var(--dark-87);
          .icon-wrapper {
            background-color: var(--main-color);
          }
        }
      }
    }
  }
  &_footer {
    padding: 15px 30px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    &-ref {
      color: var(--white);
      margin-top: 20px;
      span, a {
        font-size: 12px!important;
        color: var(--dark-58);
      }
    }
    .lang {
      padding: 5px 8px;
      background-color: var(--dark-87);
      svg {
        margin-right: 5px;
      }
      &_list {
        &-option {
          color: var(--white);
        }
      }
    }
    &-links {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      .btn-none {
        margin-top: 10px;
      }
    }
    .btn-none {
      padding: 0;
    }
    .link {
      font-weight: 500;
      text-decoration: none;
      color: var(--white);
      font-size: 14px;
    }
  }
}

.mobile-toggle {
  display: none;
}

@media screen and (max-width: 1320px) {
  .sidebar {
    display: none;
  }
  .sidebar.visible {
    display: block;
  }
  .sidebar {
    background-color: var(--dark);
    z-index: 10;
    &_header {
      display: none;
    }
    &_content {
      background-color: var(--dark);
      min-width: 100%;
      max-width: 100%;
    }
  }
  .mobile-toggle {
    display: block;
    background-color: var(--dark);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    .btn-none {
      padding: 6px !important;
      background-color: var(--dark-87);
      margin-left: auto;
    }
  }
}