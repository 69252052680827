.banner {
  box-sizing: border-box;
  padding: 20px 30px;
  &_title {
    font-weight: 600;
    color: var(--white);
    font-size: 18px;
  }
  &_subtitle {
    font-size: 15px;
    margin-bottom: 0;
  }
  &_icon {
    padding: 12px;
    background-color:var(--main-color-light);
    border-radius: var(--rounded);
  }
  .btn-slim {
    font-size: 15px;
    text-align: center;
    text-decoration: none;
  }
}


.phone-input {
  display: flex;
  &_select {
    border: 1px solid var(--dark-23);
    border-right: none!important;
    padding-left: 10px;
    border-top-left-radius: var(--rounded)!important;
    border-bottom-left-radius: var(--rounded)!important;
    &:focus, &:active, &:target {
      border: 1px solid var(--dark-23);
      outline: none!important;
      border-right: none!important;
    }
  }
  &_number {
    border-left: none!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    &:focus {
      border: 1px solid var(--dark-23)!important;
      border-left: none!important;
    }
  }
}

.input-error {
  position: absolute;
  padding: 10px 8px;
  border-radius: var(--rounded);
  background-color: var(--err-54);
  span {
    color: var(--err-100);
  }
}

.otp-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  &_input {
    width: 100%!important;
    height: 62px;
    font-size: 48px;
    border-radius: 8px;
    line-height: 60px;
    border: 1px solid var(--dark-25);
    margin-left: 12px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    &:first-of-type {
      margin-left: 0;
    }
    &::placeholder {
      color: var(--dark-25);
    }
  }
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 28px;
  &_icon {
    padding: 5px;
    border-radius: var(--rounded);
    background-color: var(--main-color);
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  &_label {
    margin-bottom: 0;
    margin-left: 8px;
    font-weight: 600;
    font-size: 18px;
  }
}


.section-title.light {
  .section-title_icon {
    background-color: var(--light);
  }
}

.select {
  width: 100%;
  position: relative;
  &_input {
    padding: 14px;
    width: 100%;
    border: 1px solid var(--dark-23);
    border-radius: var(--rounded);
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    &:focus {
      outline: none;
      border-color: var(--main-color);
    }
  }
  &_chevron {
    position: absolute;
    right: 14px;
    top: 18px;
  }
}

.product-search {
  &_content {
    position: relative;
    .form-control {
      font-weight: 600;
      padding-right: 30px!important;
      &::placeholder {
        font-weight: 400;
      }
      &:hover {
        cursor: pointer;
      }
    }
    svg {
      position: absolute;
      top: 20px;
      right: 12px;
    }
  }
}

.search-modal {
  max-height: 90vh;
  overflow-y: scroll;
  .filters {
    padding: 19px 20px;
    border-bottom: 1px solid var(--dark-23);
    .section-title {
      margin-top: 0;
    }
  }
  &_results {
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 0 20px;
    &-count {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .catalog-row {
      padding: 10px 0;
      &_image {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.catalog-row {
  margin: 20px 0;
  border: 1px solid var(--dark-25);
  background-color: var(--white);
  border-radius: var(--rounded);
  &_image {
    padding: 0 8px!important;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  &_body {
    padding: 0 8px!important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &-top, &-bottom {
      font-size: 14px;
      p {
        margin-bottom: 0;
        font-size: 16px;
      }
      .price-label {
        font-size: 16px;
        span {
          font-size: 16px;
        }
      }
      display: flex;
      justify-content: space-between;
    }
    &-top {
      margin-bottom: 8px;
    }
    &-bottom {
      &-meta {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 78%; 
        label {
          max-width: 25%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:last-of-type {
            max-width: 50%;
          }
        }
        span {
          color: var(--dark-87);
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  &_actions {
    padding: 0 8px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--dark-15);
    &-col {
      .btn-none {
        background: transparent;
        font-weight: 500;
        svg {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
}

.card {
  border: 1px solid var(--dark-23)!important;
  border-radius: var(--rounded)!important;
  &_header, &_footer {
    background-color: var(--dark-15);
    label {
      font-weight: 600;
      font-size: 14px;
      color: var(--dark-82);
    }
  }
  &_footer {
    border-top: 1px solid var(--dark-23);
  }
  &_header {
    border-bottom: 1px solid var(--dark-23);
  }
}

.notice {
  padding: 16px;
  max-width: 600px;
  margin-left: auto;
  display: flex;
  background-color: var(--white);
  border-radius: var(--rounded);
  &_icon {
    height: 100%;
    margin-right: 16px;
    &-wrapper {
      padding: 5px;
      background-color: var(--main-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--rounded);
    }
  }
  &_body {
    p {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 8px;
    }
    span {
      color: var(--dark-80);
      font-size: 14px;
    }
  }
}

.notice.light {
  .notice_icon .notice_icon-wrapper {
    background-color: var(--light);
  }
}

.btn-0 {
  border: none;
  padding: 10px 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
}

.prim-btn {
  color: var(--main-color);
  font-weight: 500;
  svg {
    margin-right: 5px;
  }
  transition: 0.2s;
  &:hover {
    color:var(--dark-87); 
  }
}

.dark-btn {
  font-weight: 500;
  color: var(--white)!important;
  background-color: var(--dark-104)!important;        
  transition: 0.2s;
  &:hover{
    background:var(--main-color);
  }
}

.gray-btn {
  font-weight: 500;
  color: var(--dark-58);
  svg {
    margin-right: 5px;
  }
  transition: 0.2s;
  &:hover {
    color:var(--dark-87); 
  }
}

.banner.main {
  h1 {
    color: var(--white);
  }
  p {
    color: var(--white);
  }
}

.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0 40px 0;
  .pagination-btn {
    padding: 8px 20px;
    background-color: var(--white)!important;
    color: var(--main-color);
    border: 1px solid var(--dark-25)!important;
    border-radius: var(--rounded);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    &_chevron {
      &-left {
        margin-right: 20px;
        rotate: 180deg;
      }
      &-right {
        margin-left: 20px;
      }
    }
  }
  .pagination-btn.disabled {
    user-select: none;
    cursor: default;
    color: var(--dark-25);
    svg {
      filter: grayscale(1);
      opacity: 0.3;
    }
  }
}

.date-filter {
  &_wrapper {
    display: flex;
    align-items: center;
  }
  &_start, &_end {
    position: relative;
    .react-calendar {
      position: absolute;
      left: 20px;
    }
  }
  .react-calendar {
    z-index: 2;
    border-radius: var(--rounded);
    border: 1px solid var(--dark-23);
    min-width: 325px;
  }
  &_input {
    display: flex;
    align-items: center;
    padding: 14px 22px;
    background-color: var(--white);
    border-radius: var(--rounded);
    border: 1px solid var(--dark-23);
    svg {
      margin-right: 8px;
    }
  }
  .filter-sep arator {
    margin: 0 18px;
  }
  .btn-none.main-btn {
    padding: 8px;
    height: 100%;
  }
}

.custom-toast {
  position: fixed;
  right: 12px;
  top: 20px;
  z-index: 10000;
  &_body {
    border-radius: var(--rounded);
    padding: 6px 30px;
    &-message {
      color: var(--white);
    }
  }
  &_body.error {
    background-color: red;
  }
  &_body.default {
    background-color: var(--main-color);
  }
}

@media screen and (max-width: 1320px) {
  .date-filter {
    width: 100%;
    &_wrapper {
      min-width: 100%;
    }
  }
}